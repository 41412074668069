import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ITService from '../../containers/Services/ITService';

const title = 'Comprehensive IT Service Management (ITSM) Solutions';

const description =
  'Keep your business running seamlessly with our full-spectrum IT Service Management (ITSM) solutions. At Tech Holding, we specialize in 24x7 IT operations, MSP services, and strategic partnerships with industry-leading vendors like Cloudflare, Kandji, CrowdStrike, Microsoft Intune, Apple Business Manager, Jamf, Vanta, and more.';

const details = {
  title,
  description,
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

const ITServicePage = ({ data }) => (
  <ITService details={details} companyQuote={data.contentfulCompanyQuote} />
);
ITServicePage.propTypes = propTypes;

export default ITServicePage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
