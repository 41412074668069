import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../../components/Layout';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import ConsumerImg from '../../../assets/ai/ConsumerTouchPoints.png';

import s from './ITService.module.scss';

const propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contactUsContent: PropTypes.string.isRequired,
    ITServiceSections: PropTypes.array.isRequired,
    contactUsSummary: PropTypes.string.isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/it-service.png',
  ogTitle: 'Comprehensive IT Service Management (ITSM) Solutions at Tech Holding',
  ogDesc:
    'we provide end-to-end IT Service Management (ITSM) solutions designed to keep your business running smoothly, securely, and efficiently. With 24x7 IT operations expertise, MSP capabilities, and experience working with leading vendors—including Cloudflare, Kandji, CrowdStrike, Microsoft Intune, Apple Business Manager, Jamf, Vanta, and many more—we deliver tailored solutions that empower organizations to streamline IT processes, enhance security, and ensure compliance.',
  ogPath: '/services/itsm',
};

const updatedSeo = getSeoConfig(seoParams);

const ITService = ({ details, companyQuote }) => {
  const { title } = details;
  return (
    <Layout
      currentPage="/services/itsm"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.bgGray}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.headerContent}>
            <div className={s.title}>
              <h2 data-test="title">{title}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className={s.pageContainer} data-test="pageContainer">
        <h3>Transforming IT Operations with Automation, Security, and 24x7 Support</h3>
        <p className={s.text}>
          At Tech Holding, we provide end-to-end IT Service Management (ITSM) solutions designed to
          keep your business running smoothly, securely, and efficiently. With 24x7 IT operations
          expertise, MSP capabilities, and experience working with leading vendors—including
          Cloudflare, Kandji, CrowdStrike, Microsoft Intune, Apple Business Manager, Jamf, Vanta,
          and many more—we deliver tailored solutions that empower organizations to streamline IT
          processes, enhance security, and ensure compliance.
        </p>
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <div className={s.image}>
            <img className={s.emoji} src={ConsumerImg} alt="" />
          </div>
          <h2>Our ITSM Offerings:</h2>
          <div className={s.flex}>
            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>
                  <strong>Fully Managed ITSM & Staff Augmentation</strong>
                </h3>
                <ul>
                  <li>
                    <strong>Fully Managed ITSM –</strong>
                    We take complete ownership of your IT operations, offering a seamless,
                    worry-free experience.
                  </li>
                  <li>
                    <strong>Staff Augmentation & Onsite Support –</strong>
                    Need additional IT staff? We provide skilled professionals to enhance your team.
                  </li>
                  <li>
                    <strong>Hybrid IT Support –</strong>
                    <span>
                      A combination of remote and onsite support to fit your business needs.
                    </span>
                  </li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>
                  <strong>Automated ITSM Process Design</strong>
                </h3>
                <ul>
                  <li>Implement AI-driven and automated workflows for ITSM operations.</li>
                  <li>
                    Reduce manual intervention, improve response times, and enhance IT service
                    efficiency.
                  </li>
                  <li>Integration with ITSM platforms to optimize service delivery.</li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>
                  <strong>Security-First ITSM Approach & Industry Best Practices</strong>
                </h3>
                <ul>
                  <li>
                    <strong>Zero Trust Security Framework – </strong>
                    <span>
                      Implement access controls that verify every request and user before granting
                      access.
                    </span>
                  </li>
                  <li>
                    <strong>Continuous Threat Monitoring – </strong>
                    <span>
                      Leverage CrowdStrike, Cloudflare, and other security solutions to proactively
                      detect and mitigate threats.
                    </span>
                  </li>
                  <li>
                    <strong> Mobile Device Management, Security & Compliance – </strong>
                    <span>
                      Secure all devices with Microsoft Intune, Jamf, or Kandji for comprehensive
                      device management.
                    </span>
                  </li>
                  <li>
                    <strong> Network Security & Traffic Protection – </strong>
                    <span>
                      Implement secure firewalls, DDoS protection, and data encryption at rest and
                      in transit.
                    </span>
                  </li>
                  <li>
                    <strong>Security Patch Management – </strong>
                    <span>
                      Ensure all systems and devices remain up to date with the latest security
                      patches.
                    </span>
                  </li>
                  <li>
                    <strong>Incident Response & Disaster Recovery – </strong>
                    <span>
                      Quick action plans to mitigate risks, restore systems, and ensure business
                      continuity.
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>
                  <strong>Compliance Readiness & Certification Support</strong>
                </h3>
                <ul>
                  <li>Ensure readiness for ISO, SOC 2, HIPAA, and other regulatory frameworks.</li>
                  <li>
                    Partnership with
                    <strong> Vanta </strong>
                    to help organizations achieve and maintain compliance.
                  </li>
                  <li>Streamlined policy implementation, audit support, and risk assessments.</li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>
                  <strong>IT Helpdesk & 24x7 Support Services</strong>
                </h3>
                <ul>
                  <li>Dedicated IT helpdesk for employees and end-users.</li>
                  <li>24x7 remote IT support, with proactive monitoring and issue resolution.</li>
                  <li>
                    <strong>Multi-channel support: </strong>
                    Phone, Email, Chat, and Ticketing System.
                  </li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>
                  <strong>Mobile Device Management (MDM) & Endpoint Security</strong>
                </h3>
                <ul>
                  <li>
                    <strong>MDM Implementation & Support – </strong>
                    <span>
                      Securely manage Apple and Windows devices with Kandji, Jamf, Microsoft Intune,
                      and Apple Business Manager.
                    </span>
                  </li>
                  <li>
                    <strong> Endpoint Security & Threat Management – </strong>
                    <span>
                      Leveraging CrowdStrike and Cloudflare for real-time threat detection and
                      response.
                    </span>
                  </li>
                  <li>
                    Enforce security policies, remote wipe capabilities, and device compliance
                    enforcement.
                  </li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>
                  <strong>Custom ITSM Solutions Tailored to Your Needs</strong>
                </h3>
                <ul>
                  <li>
                    Whether you need fully managed IT services, an extension of your existing IT
                    team, or a hybrid model, we deliver solutions that fit your business goals and
                    budget.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <h2>Why Choose Tech Holding?</h2>
          <div className={s.flex}>
            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>
                  <strong>Modern Security-First Approach</strong>
                </h3>
                <p className={s.text}>
                  Industry best practices including Zero Trust, proactive threat monitoring, and
                  security patching.
                </p>
              </div>

              <div className={s.blockDetails}>
                <h3>
                  <strong>Automation & Efficiency</strong>
                </h3>
                <p className={s.text}>
                  Optimized IT processes with automated workflows and AI-driven ITSM.
                </p>
              </div>
            </div>
            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>
                  <strong>Compliance & Risk Management</strong>
                </h3>
                <p className={s.text}>
                  Strong experience with ISO 27001, SOC 2, HIPAA, PCI DSS and other regulatory
                  frameworks
                </p>
              </div>
              <div className={s.blockDetails}>
                <h3>
                  <strong>Flexible Engagement Models</strong>
                </h3>
                <p className={s.text}>
                  From fully managed IT to staff augmentation, we provide IT solutions that scale
                  with your business.24x7 Support & Global Reach – Around-the-clock IT support for
                  seamless operations across locations.
                </p>
              </div>
            </div>
          </div>

          <p>
            Let’s discuss how our ITSM solutions can optimize your IT operations, enhance security,
            and help you achieve compliance.
          </p>
        </div>
      </div>
    </Layout>
  );
};

ITService.propTypes = propTypes;
export default ITService;
